import type { RouteLocationNormalized } from 'vue-router'

export function useExperiments() {
  const experiments = useState('experiments', () => new Map<string, string>())
  const listenersBinded = useState('experimentListenersBinded', () => false)

  const parseExperiment = (queryParam: string) => {
    const parts = queryParam.split(':')

    if (parts.length == 2) {
      experiments.value.set(parts[0], parts[1])
    } else {
      console.error(`Failed to parse experiment: ${queryParam}`)
    }
  }

  const bindWindowEventListener = () => {
    window.addEventListener('experiment', (event: Event) => {
      if (event instanceof CustomEvent) {
        if (typeof event.detail === 'string') {
          parseExperiment(event.detail)
        }
      }
    })
  }

  const bindQueryParameterExperimentListener = () => {
    onBeforeRouteUpdate((to: RouteLocationNormalized) => {
      const expQuery = to.query['exp']

      if (typeof expQuery === 'string') {
        parseExperiment(expQuery)
      } else if (Array.isArray(expQuery)) {
        expQuery.forEach((value) => {
          if (value !== null) {
            parseExperiment(value)
          }
        })
      }
    })
  }

  const get = (name: string): string | undefined => {
    return experiments.value.get(name)
  }

  const set = (name: string, value: string) => {
    experiments.value.set(name, value)
  }

  onBeforeMount(() => {
    if (listenersBinded.value) {
      bindWindowEventListener()
      bindQueryParameterExperimentListener()

      listenersBinded.value = true
    }
  })

  return {
    get,
    set
  }
}